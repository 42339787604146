import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  findAds3,
  resetFindProps,
  setFindProps,
  customSetFindProps,
  getCarModel,
  removeCarModel,
  resetCarModel,
} from "../../store/actions";
import {
  setSearchProps,
  countFilters,
  numberWithCommas,
} from "../../lib/utils";
import { ArrowRight, ArrowUpRed } from "../svg-icons";
import { getYearsWithAll } from "../../data";
import { getFormattedZipCodePlacesLocationsSwiss } from "../../data/zip-cities-swiss";
import { getPerimeterOptions } from "../../screens/advanced-search/data";
import Select from "../select/index";
import TextField from "../text-field/index";
import Button from "../../components/button/index";
import FormControl from "../form-control";
import "./index.css";
import { useUrlMakeAndModel } from "../../hooks";
import { useSelectedValue } from "../../hooks";

export const FILTER_KEYS = [
  "idMake",
  "idModel",
  "equipmentLine",
  "idBodyType",
  "priceFrom",
  "priceTo",
  "yearFrom",
  "yearTo",
  "mileageFrom",
  "mileageTo",
  "idTransmission",
  "idFuelType",
  "powerFrom",
  "powerTo",
];

const aside = (props) => {
  const {
    dispatch,
    makeWithAll,
    carModelWithAll,
    find_data,
    ads_total,
    setActivePage,
    showSaveSearchBtn,
    fuelTypeWithAll,
    bodyTypeWithAll,
    transmissionWithAll,
    goToAdvancedSearch,
  } = props;
  const { t, i18n } = useTranslation(["common, results, home"]);
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const transformed_bodytype = bodyTypeWithAll.filter((item) => item.name.EN);
  const transformed_fueltype = fuelTypeWithAll.filter((item) => item.name.EN);
  const perimetarOptions = getPerimeterOptions();
  const transformed_transmission = transmissionWithAll.filter(
    (item) => item.name
  );
  const yearsWithAll = getYearsWithAll();
  const { idMake, idModel, modelFromUrl, updateUrl, clearUrl } =
    useUrlMakeAndModel();
  const zipCodePlacesLocationsSwiss = getFormattedZipCodePlacesLocationsSwiss();
  const { getSelectedValue } = useSelectedValue();

  useEffect(() => {
    const initialCount = countFilters(find_data, FILTER_KEYS);
    setSelectedFilterCount(initialCount);
  }, [find_data]);

  useEffect(() => {
    const findByUrlParams = () => {
      if (idMake && (find_data?.idMake?.length ?? 0) === 0) {
        dispatch(
          customSetFindProps("idMake", [idMake, ...(find_data?.idMake ?? [])])
        );
      }
      // call find_ads only if we have MAKE defined in url
      if (idMake && !modelFromUrl) {
        findHandler(false);
      }
    };

    findByUrlParams();
  }, []);

  useEffect(() => {
    if (
      modelFromUrl &&
      idModel &&
      (find_data?.idModel?.length ?? 0) === 1 &&
      !find_data?.idModel.includes(idModel)
    ) {
      const removedNulls = (find_data?.idModel ?? []).filter((x) => !!x);
      dispatch(customSetFindProps("idModel", [idModel, ...removedNulls]));
      findHandler(false);
    }
  }, [idModel]);

  useEffect(() => {
    if (
      !(find_data?.idMake?.length > 0 && find_data.idMake[0] !== null) &&
      !(find_data?.equipmentLine?.length > 0)
    ) {
      // dispatch(getCarModel(0, 0));
      if (!idMake) {
        dispatch(customSetFindProps("idMake", [null]));
      }
      if (!idModel) {
        dispatch(customSetFindProps("idModel", [null]));
      }
      dispatch(customSetFindProps("equipmentLine", []));
    } else if (
      Array.isArray(find_data.idMake) &&
      !Array.isArray(find_data.idModel)
    ) {
      dispatch(customSetFindProps("idModel", [null]));
    }
  }, []);

  const setSearchPropsHandler = (key, value) => {
    setSearchProps(dispatch, key, value);
  };

  const handleClearFilters = () => {
    dispatch(setFindProps("fromCount", 0));
    dispatch(resetFindProps());
    dispatch(getCarModel(0, 0));
    dispatch(customSetFindProps("idMake", [null]));
    dispatch(customSetFindProps("idModel", [null]));
    dispatch(findAds3());
    setActivePage(1);
    clearUrl();
  };

  const findHandler = (updateUrlFlag = false) => {
    dispatch(setFindProps("fromCount", 0));
    dispatch(findAds3());
    setActivePage(1);
    if (updateUrlFlag) updateUrl();
  };

  const addMakeOrModel = (key, value, index) => {
    value = value === -1 ? null : value;
    if (key === "idMake") {
      if (value !== null) {
        dispatch(getCarModel(value, index));
      }
      dispatch(resetCarModel(index));
      const newMake = [...find_data.idMake];
      newMake[index] = value;
      const newModel = [...find_data.idModel];
      newModel[index] = null;
      const newVariants = [...find_data.equipmentLine];
      newVariants[index] = "";
      dispatch(customSetFindProps("idMake", newMake));
      dispatch(customSetFindProps("idModel", newModel));
      dispatch(customSetFindProps("equipmentLine", newVariants));
    } else if (key === "idModel") {
      const newModel = [...find_data.idModel];
      newModel[index] = value;
      dispatch(customSetFindProps("idModel", newModel));
    } else if (key === "equipmentLine") {
      let newVariants = [...find_data.equipmentLine];

      newVariants[index] = value;
      dispatch(customSetFindProps("equipmentLine", newVariants));
    }
  };

  const addAnotherVehicle = () => {
    dispatch(customSetFindProps("idMake", [...find_data.idMake, null]));
    dispatch(customSetFindProps("idModel", [...find_data.idModel, null]));
    dispatch(
      customSetFindProps("equipmentLine", [...find_data.equipmentLine, ""])
    );
  };

  const deleteVehicle = (indexPosition) => {
    dispatch(removeCarModel(indexPosition));
    const newMake = find_data.idMake.filter(
      (_, index) => index !== indexPosition
    );
    const newModel = find_data.idModel.filter(
      (_, index) => index !== indexPosition
    );
    const newVariants = find_data.equipmentLine
      ? find_data.equipmentLine.filter((_, index) => index !== indexPosition)
      : [];
    dispatch(customSetFindProps("idMake", newMake));
    dispatch(customSetFindProps("idModel", newModel));
    dispatch(customSetFindProps("equipmentLine", newVariants));
  };

  const handleCityChange = (newValue) => {
    dispatch(setFindProps("latitude", newValue.latitude));
    dispatch(setFindProps("longitude", newValue.longitude));
    dispatch(setFindProps("zipcode", newValue.zipCode));
  };

  const handleRadiusChange = (event, value) => {
    dispatch(setFindProps("radius", value.radius ?? null));
  };

  return (
    <aside className="results-filters">
      <header>
        <div>
          <p>
            {t("common:filters")} <span>({selectedFilterCount})</span>
          </p>
          {ArrowUpRed}
        </div>
        <Button
          click={handleClearFilters}
          className="clear-all-button"
          label={t("common:clear-all")}
          color="transparent"
        />
      </header>
      <main>
        {find_data?.idMake?.length > 0 &&
          find_data?.idMake.map((_, index) => (
            <div key={index} className="mb-1">
              <FormControl
                inputType="select"
                label={t("common:make")}
                placeholder={t("common:all")}
                data={makeWithAll}
                value={getSelectedValue(makeWithAll, "idmake", "idMake", index)}
                handleChange={(_, newValue) =>
                  addMakeOrModel(
                    "idMake",
                    newValue ? newValue.idmake : null,
                    index
                  )
                }
              />
              <FormControl
                inputType="select"
                label={t("home:model")}
                data={
                  Array.isArray(carModelWithAll[index])
                    ? carModelWithAll[index]
                    : []
                }
                value={getSelectedValue(
                  Array.isArray(carModelWithAll[index])
                    ? carModelWithAll[index]
                    : [],
                  "idmodel",
                  "idModel",
                  index
                )}
                handleChange={(_, newValue) =>
                  addMakeOrModel(
                    "idModel",
                    newValue ? newValue.idmodel : null,
                    index
                  )
                }
              />
              <FormControl
                name="variant"
                type="text"
                placeholder={t("sell:variant-placeholder")}
                inputType="textfield"
                label={t("common:variant")}
                value={find_data?.equipmentLine[index] || ""}
                handleChange={(event) =>
                  addMakeOrModel(
                    "equipmentLine",
                    event.target.value,
                    index,
                    event.target.value
                  )
                }
              />
              {find_data?.idMake?.length > 1 ? (
                <div
                  className="addOrDelete"
                  onClick={() => deleteVehicle(index)}
                >
                  {`- ${t("common:remove")}`}
                </div>
              ) : null}
              {index + 1 === find_data?.idMake?.length ? (
                <div className="addOrDelete" onClick={addAnotherVehicle}>
                  {`+ ${t("results:add-make-model-aside")}`}
                </div>
              ) : null}
            </div>
          ))}
        <FormControl
          label={t("dealerSearch:zip-place")}
          inputType="select"
          placeholder=""
          data={zipCodePlacesLocationsSwiss}
          value={getSelectedValue(
            zipCodePlacesLocationsSwiss,
            "zipCode",
            "zipcode"
          )}
          handleChange={(_, newValue) => handleCityChange(newValue)}
        />
        <FormControl
          label={t("advancedSearch:perimetar")}
          inputType="select"
          placeholder={t("common:all")}
          data={perimetarOptions}
          value={perimetarOptions.find(
            (item) => item.radius === find_data.radius
          )}
          handleChange={handleRadiusChange}
        />
        <FormControl
          inputType="select"
          label={t("common:body-type")}
          placeholder={t("common:all")}
          data={transformed_bodytype}
          value={getSelectedValue(
            transformed_bodytype,
            "idbodytype",
            "idBodyType"
          )}
          handleChange={(_, newValue) =>
            setSearchPropsHandler(
              "idBodyType",
              newValue ? newValue.idbodytype : null
            )
          }
        />
        <FormControl label={t("common:price")} className="filters-flex-row">
          <TextField
            placeholder={t("common:from")}
            type="number"
            value={find_data.priceFrom ? find_data.priceFrom : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("priceFrom", Number(event.target.value))
            }
          />
          <TextField
            placeholder={t("common:to")}
            type="number"
            value={find_data.priceTo ? find_data.priceTo : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("priceTo", Number(event.target.value))
            }
          />
        </FormControl>
        <FormControl
          label={t("common:first-registration")}
          className="filters-flex-row"
        >
          <Select
            placeholder={t("common:from")}
            label="yearFrom"
            data={yearsWithAll}
            handleChange={(_, newValue) =>
              setSearchPropsHandler("yearFrom", newValue ? newValue.year : "")
            }
            defaultValue={yearsWithAll.find(
              (item) => item.year === find_data.yearFrom
            )}
          />
          <Select
            placeholder={t("common:to")}
            label="yearTo"
            data={yearsWithAll}
            handleChange={(_, newValue) =>
              setSearchPropsHandler("yearTo", newValue ? newValue.year : "")
            }
            defaultValue={yearsWithAll.find(
              (item) => item.year === find_data.yearTo
            )}
          />
        </FormControl>
        <FormControl label={t("common:mileage")} className="filters-flex-row">
          <TextField
            placeholder={t("common:from")}
            type="number"
            value={find_data.mileageFrom ? find_data.mileageFrom : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("mileageFrom", Number(event.target.value))
            }
          />
          <TextField
            placeholder={t("common:to")}
            type="number"
            value={find_data.mileageTo ? find_data.mileageTo : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("mileageTo", Number(event.target.value))
            }
          />
        </FormControl>
        <FormControl label={t("common:power")} className="filters-flex-row">
          <TextField
            placeholder={t("common:from")}
            type="number"
            value={find_data.powerFrom ? find_data.powerFrom : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("powerFrom", Number(event.target.value))
            }
          />
          <TextField
            placeholder={t("common:to")}
            type="number"
            value={find_data.powerTo ? find_data.powerTo : ""}
            changeHandler={(event) =>
              setSearchPropsHandler("powerTo", Number(event.target.value))
            }
          />
        </FormControl>
        <FormControl
          inputType="select"
          label={t("common:transmission")}
          placeholder={t("common:all")}
          data={transformed_transmission.map((item) => {
            if (item.idtransmission === -1) {
              return item;
            }
            return {
              ...item,
              name: { [i18n.language.toUpperCase()]: item.name },
            };
          })}
          value={getSelectedValue(
            transformed_transmission,
            "idtransmission",
            "idTransmission"
          )}
          handleChange={(_, newValue) =>
            setSearchPropsHandler(
              "idTransmission",
              newValue?.idtransmission ?? ""
            )
          }
        />
        <FormControl
          inputType="select"
          label={t("common:fuel-type")}
          placeholder={t("common:all")}
          data={transformed_fueltype}
          value={getSelectedValue(
            transformed_fueltype,
            "idfueltype",
            "idFuelType"
          )}
          handleChange={(_, newValue) =>
            setSearchPropsHandler(
              "idFuelType",
              newValue ? newValue.idfueltype : ""
            )
          }
        />
        <Button
          click={() => findHandler(true)}
          className="results-aside-btn"
          label={
            t("common:search")
            //+
            //" " +
            //`(${ads_total ? numberWithCommas(ads_total) : "0"})`
          }
          color="white"
        />
        <Button
          color="transparent"
          className="results-aside-advanced-search"
          click={goToAdvancedSearch}
        >
          <span>{t("common:advanced-search")}</span>
          <ArrowRight />
        </Button>
        <Button
          click={props.openDialogBoxHandler}
          className="results-aside-saved-search"
          style={{ display: showSaveSearchBtn ? "block" : "none" }}
          label={t("results:savesearch")}
          color="transparent"
        />
      </main>
    </aside>
  );
};

const stateToProps = (state) => {
  const {
    makeWithAll,
    carModelWithAll,
    find_data,
    ads_total,
    transmission,
    fuelTypeWithAll,
    bodyTypeWithAll,
    transmissionWithAll,
    loading,
  } = state.car;
  return {
    makeWithAll,
    carModelWithAll,
    find_data,
    ads_total,
    transmission,
    fuelTypeWithAll,
    bodyTypeWithAll,
    transmissionWithAll,
    loading,
  };
};

export default connect(stateToProps)(aside);
