import { useEffect, useRef, forwardRef } from "react";
import i18n from "i18next";
import { connect } from "react-redux";
import realMoment from "moment";
import { getConfig } from "../.app-config";
import { getCarModel, setFindProps } from "../store/actions";
import history from "./history";
import navConfig from "../.nav-config";
import { default as BigNumberInstance } from "./bignumber";
import { default as momentInstance } from "./moment";
import { startUpload } from "../store/comm/sender";
import { USER_TYPES } from "../types";
import { resetCarModel } from "../store/actions";
export const BigNumber = BigNumberInstance;
export const moment = momentInstance;
export const getAppConfig = getConfig;

export const connectWithForwardRef =
  (
    mapStateToProps = null,
    mapDispatchToProps = null,
    mergeProps = null,
    options = {}
  ) =>
  (component) =>
    connect(mapStateToProps, mapDispatchToProps, mergeProps, {
      ...options,
      forwardRef: true,
    })(forwardRef(component));

export const combineRefs = (...refs) => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const getComponentByDomNode = (querySelector) => {
  const dom = global.window.document.querySelector(querySelector);
  for (const key in dom) {
    if (key.startsWith("__reactInternalInstance$")) {
      const reactNode = dom[key];
      if (reactNode._currentElement) {
        // v15 < react < v16
        const compInternals = reactNode._currentElement;
        if (compInternals) {
          const compWrapper = compInternals._owner;
          if (compWrapper) {
            return compWrapper._instance;
          }
        }
      } else {
        // react v16+
        return reactNode && reactNode.return && reactNode.return.stateNode;
      }
    }
  }
  return null;
};

// declarative "nav to"
export const forwardTo = (location, historyState = null) => {
  if (location === history.location.pathname) {
    return;
  }
  history.push(location, historyState);
  if (global.window) {
    const drawer = getComponentByDomNode("aside.mdc-drawer");
    if (drawer && drawer.props.open) {
      drawer.props.onClose();
    }
  }
};

export const goBack = () => history.goBack();

export const getDefaultRoute = (protectedRoute) => {
  protectedRoute = protectedRoute ? protectedRoute : false;
  let defaultRoute = navConfig.routes.find(
    (item) =>
      item.default === true &&
      (protectedRoute ? item.protected === true : item.protected !== true)
  );
  if (!defaultRoute) {
    defaultRoute = navConfig.routes.find((item) =>
      protectedRoute ? item.protected === true : item.protected !== true
    );
  }
  return defaultRoute || navConfig.routes[0];
};

export const getHistoryState = (path = null) =>
  !path ? null : getPropertyByPath(history, "location.state." + path);

export const getScrollbarWidth = (elm) => {
  let scrollbarWidth = 0;
  if (elm) {
    scrollbarWidth = elm.offsetWidth - elm.clientWidth;
  } else if (global && global.window) {
    scrollbarWidth =
      global.window.innerWidth -
      global.window.document.documentElement.clientWidth;
  }
  return scrollbarWidth;
};

/**
 * Helper utility function
 * checking if `item` is not `undefined` and not `null`
 * @public
 * @function
 * @param {object|string|array|number} item - source object with property "path" we want to check
 * @return {boolean} item is defined or not
 */
export const isDefined = (item) => item !== undefined && item !== null;

/**
 * Check if object have defined some path.
 * (e.g. path='someObj.property1.property2')
 * @public
 * @param {Object} object object for check
 * @param {String} [path=''] object for check
 * @return {Boolean}
 * @function
 */
export const deepIsDefined = (object, path = "") => {
  let def = true;
  let obj = { ...object };
  const arr = path.split(".");

  arr.forEach((level) => {
    if (!obj[level]) {
      def = false;
      return;
    }
    obj = obj[level];
  });
  return def;
};

export const setPropertyByPath = (object, path = "", value) => {
  if (path.indexOf(".") === -1) {
    if (isDefined(value)) {
      object[path] = value;
    }
    return object;
  } else {
    let paths = path.split(".");
    const singlePath = paths[0];
    paths = paths.slice(1, paths.length);
    if (!object[singlePath]) {
      object[singlePath] = {};
    }
    setPropertyByPath(object[singlePath], paths.join("."), value);
    return object;
  }
};

export const getPropertyByPath = (object, path = "") => {
  if (path.indexOf(".") === -1) {
    return object[path];
  } else {
    let paths = path.split(".");
    const singlePath = paths[0];
    paths = paths.slice(1, paths.length);
    if (!object[singlePath]) {
      return null;
    }
    return getPropertyByPath(object[singlePath], paths.join("."));
  }
};

export const padNumber = (num, size) => {
  var s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

/**
 * Simulate wait of some operation
 * @public
 * @function
 * @param {number} ms milliseconds
 * @return {Promise}
 */
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Remove white spaces from string from the left side
 * @public
 * @function
 * @param {string} s string to parse
 * @param {string} char_mask
 * @return {string}
 */
export const lTrim = (s, char_mask) => {
  const whitespace = " \t\n\r" + (char_mask || "");

  if (whitespace.indexOf(s.charAt(0)) !== -1) {
    let j = 0,
      i = s.length;
    while (j < i && whitespace.indexOf(s.charAt(j)) !== -1) {
      j++;
    }
    s = s.substring(j, i);
  }

  return s;
};

/**
 * Remove white spaces from string from the right side
 * @public
 * @function
 * @param {string} s string to parse
 * @param {string} char_mask
 * @return {string}
 */
export const rTrim = (s, char_mask) => {
  const whitespace = " \t\n\r" + (char_mask || "");

  if (whitespace.indexOf(s.charAt(s.length - 1)) !== -1) {
    let i = s.length - 1;
    while (i >= 0 && whitespace.indexOf(s.charAt(i)) !== -1) {
      i--;
    }
    s = s.substring(0, i + 1);
  }

  return s;
};

/**
 * Remove white spaces from string
 * @public
 * @function
 * @param {string} s string to parse
 * @param {string} char_mask
 * @return {string}
 */
export const trim = (s, char_mask) => rTrim(lTrim(s, char_mask), char_mask);

export const fileSize = (sizeB, unit) => {
  const validUnits = ["B", "kB", "MB", "GB", "TB"];
  const dv = 1024;
  const intSize = parseInt(sizeB, 10);
  let unitIndex = 0;
  let size = new BigNumber(intSize);
  if (unit) {
    if (
      validUnits.map((vu) => vu.toLowerCase()).indexOf(unit.toLowerCase()) ===
      -1
    ) {
      if (unit.indexOf("i") !== -1 || unit.indexOf("I") !== -1) {
        console.error(
          'BS "bi-bi-bytes" units (kiB, MiB, GiB, TiB) are not supported!'
        );
      } else {
        console.error('Invalid file size unit "' + unit + '" provided!');
      }
      return intSize;
    }
    if (unit.toLowerCase() === "kb") {
      size = size.div(dv);
      unitIndex = 1;
    } else if (unit.toLowerCase() === "mb") {
      size = size.div(dv).div(dv);
      unitIndex = 2;
    } else if (unit.toLowerCase() === "gb") {
      size = size.div(dv).div(dv).div(dv);
      unitIndex = 3;
    } else if (unit.toLowerCase() === "tb") {
      size = size.div(dv).div(dv).div(dv).div(dv);
      unitIndex = 4;
    } else {
      unitIndex = 0;
    }
  } else {
    while (size.toNumber() > dv && unitIndex < validUnits.length - 1) {
      size = size.div(dv);
      unitIndex++;
    }
  }
  return (
    size.toFormat(2, BigNumber.ROUND_HALF_CEIL) + " " + validUnits[unitIndex]
  );
};

export const getCssVar = (varName) => {
  const varValue = getComputedStyle(document.documentElement).getPropertyValue(
    varName
  );
  return varValue || null;
};

export const form2data = (
  elements,
  ignoreEmpty = false,
  addFileNamesToRoot = false
) => {
  return [].reduce.call(
    elements,
    (data, element) => {
      if (
        element.tagName.toLowerCase() === "button" ||
        ["button", "submit", "reset"].includes(element.type) ||
        element.classList.contains("ignore-element")
      ) {
        return data;
      }
      if (!element.name) {
        console.error("No name attribute", element);
        throw new Error(
          "This is uncontrolled form elements setup and some of them are missing name attribute"
        );
      }
      if (!element.validity.valid) {
        if (!data.errors) {
          data.errors = {};
        }
        const errors = [];
        for (const vKey in element.validity) {
          if (vKey !== "valid") {
            if (element.validity[vKey]) {
              errors.push(vKey);
            }
          }
        }
        data.errors[element.name] = errors.join(", ");
      }
      if (!["checkbox", "radio"].includes(element.type) || element.checked) {
        if (ignoreEmpty && !element.value) {
          return data;
        }
        if (element.type === "file") {
          if (!data.files) {
            data.files = {};
          }
          if (element.multiple) {
            if (addFileNamesToRoot) {
              data[element.name] = [].reduce.call(
                element.files,
                (values, file) => {
                  return values.concat(file.name);
                },
                []
              );
            }
            data.files[element.name] = element.files;
          } else {
            if (addFileNamesToRoot) {
              data[element.name] = element.files[0].name;
            }
            data.files[element.name] = element.files[0];
          }
        } else if (element.options && element.multiple) {
          data[element.name] = [].reduce.call(
            element.options,
            (values, option) => {
              return option.selected ? values.concat(option.value) : values;
            },
            []
          );
        } else if (element.name.substr(-2) === "[]") {
          const arrayName = element.name.substr(0, element.name.length - 2);
          data[arrayName] = (data[arrayName] || []).concat(element.value);
        } else {
          data[element.name] =
            element.type === "checkbox" && element.value === "on"
              ? true
              : element.value;
        }
      }
      return data;
    },
    {}
  );
};

export const form2JSON = (
  elements,
  ignoreEmpty = false,
  addFileNamesToRoot = false
) => JSON.stringify(form2data(elements, ignoreEmpty, addFileNamesToRoot));

export function pairMessages(message) {
  message = JSON.parse(message);
  let storedMessages = JSON.parse(localStorage.getItem("messages"));
  storedMessages = storedMessages.map(JSON.parse);
  const messagePaired = storedMessages.find((obj) => {
    return obj.seq == message.seq;
  });
  message.method =
    messagePaired.method.toUpperCase() + message.method.toUpperCase();
  message.request = messagePaired;
  return message;
}

export const isEmptyObject = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
};

export const emptyString = "--";

export function image64toCanvasRef(canvasRef, image64, pixelCrop, imageRef) {
  const canvas = canvasRef;
  // canvas.width = pixelCrop.width;
  // canvas.height = pixelCrop.height;

  const image = new Image();
  image.src = image64;
  const scaleX = image.naturalWidth / imageRef.width;
  const scaleY = image.naturalHeight / imageRef.height;
  canvas.width = Math.ceil(pixelCrop.width * scaleX);
  canvas.height = Math.ceil(pixelCrop.height * scaleY);
  const ctx = canvas.getContext("2d");
  image.onload = function () {
    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY
    );
  };
}

export function downloadBase64File(base64Data, filename) {
  var element = document.createElement("a");
  element.setAttribute("href", base64Data);
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export function base64StringtoFile(base64String, filename) {
  var arr = base64String.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function extractImageFileExtensionFromBase64(base64Data) {
  return base64Data.substring(
    "data:image/".length,
    base64Data.indexOf(";base64")
  );
}

export function str2ab(str) {
  const buf = new ArrayBuffer(str.length * 2); // 2 bytes for each char
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "CHF",
  minimumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const saveMessage = () => {
  console.log("save message");
};

export const adImagesURLreplacer = (ads) => {
  const updatedData = [...ads];
  let url;
  ads.map((item, aIndex) => {
    if (!item.photos || item.photos.length === 0) return;
    item.photos.forEach((photo, pIndex) => {
      if (photo.includes("http://carapp.piip.io:4000/file_download/")) {
        url = photo.replace(
          "http://carapp.piip.io:4000/file_download/",
          "https://carappcdn.piip.io/file_download/"
        );
      }
      if (
        photo.includes("https://carapp.piip.io:4000/file_download/fotobucket/")
      ) {
        url = photo.replace(
          "https://carapp.piip.io:4000/file_download/fotobucket/",
          "https://carappcdn.piip.io/file_download/"
        );
      }
      if (photo.includes("http://localhost:4000/file_download/undefined/")) {
        url = photo.replace(
          "http://localhost:4000/file_download/undefined/",
          "https://carappcdn.piip.io/file_download/"
        );
      } else {
        url = photo;
      }
      updatedData[aIndex].photos[pIndex] = url;
    });
    return item;
  });

  return updatedData;
};

export const transformData = (data, keys, values, twoDimArrIndex = null) => {
  const transformedData = data.map((item, index) => {
    let newProps = null;
    if (item.name.EN) {
      item.name.EN = item.name.EN.trim(); // Ensure name is trimmed
    }
    keys.forEach((key, kindex) => {
      newProps = {
        ...newProps,
        [key]: Array.isArray(values[kindex])
          ? twoDimArrIndex !== null
            ? values[twoDimArrIndex][index]
            : values[kindex][index]
          : values[kindex],
      };
    });
    return { ...item, ...newProps };
  });
  return transformedData;
};

export const prepareDataForSocetRequest = (object, name) => {
  const flatArray = (propName, initialValue = [], reduxPropName = null) => {
    try {
      const arr = Array.isArray(object[reduxPropName ?? propName])
        ? object[reduxPropName ?? propName]
        : initialValue;
      return {
        [propName]: arr.filter((x) => !!x),
      };
    } catch (error) {
      return {
        [propName]: null,
      };
    }
  };

  const flatArrayForMakeModel = (propName) => {
    try {
      const arr = Array.isArray(object[propName]) ? object[propName] : null;

      if (!arr || arr.length === 0) return { [propName]: null };

      if (propName === "idModel") {
        if (
          !object.idMake ||
          !Array.isArray(object.idMake) ||
          object.idMake.length === 0 ||
          object.idMake[0] === null
        ) {
          return { idModel: null };
        }
        const normalized = arr.map((modelArr) => {
          if (Array.isArray(modelArr)) {
            return modelArr.length === 1 && modelArr[0] === null
              ? [null]
              : modelArr.filter((id) => id !== null);
          }
          return modelArr === null ? [null] : [modelArr];
        });

        return { [propName]: normalized };
      }
      if (propName === "idMake") {
        if (arr.length === 1 && arr[0] === null) {
          return { [propName]: null };
        }
        const isValid = arr.every(
          (item) => typeof item === "number" || item === null
        );
        return {
          [propName]: isValid ? arr.filter((item) => item !== null) : null,
        };
      }

      const isValid = arr.every((item) => typeof item === "number");
      return { [propName]: isValid ? arr : null };
    } catch {
      return { [propName]: null };
    }
  };

  const getProp = (propName, initialValue = null) => ({
    [propName]: object[propName] ?? initialValue,
  });
  let transformedData = {};
  let idMakeData = flatArrayForMakeModel("idMake");
  let idModelData = flatArrayForMakeModel("idModel");
  switch (name) {
    case "find":
      if (
        idMakeData.idMake &&
        idModelData.idModel &&
        idMakeData.idMake.length !== idModelData.idModel.length
      ) {
        idMakeData.idMake = null;
        idModelData.idModel = null;
      }
      transformedData = {
        ...getProp("byUid"),
        ...idMakeData,
        ...idModelData,
        ...getProp("yearFrom"),
        ...getProp("yearTo"),
        ...getProp("mileageFrom"),
        ...getProp("mileageTo"),
        ...getProp("priceFrom"),
        ...getProp("priceTo"),
        ...getProp("idFuelType"),
        ...flatArray("idBodyType", [object["idBodyType"]]),
        ...flatArray("equipmentLine"),
        ...flatArray("idBodyColor", [], "idbodycolor"),
        ...getProp("metallicColor"),
        ...flatArray("idInteriorColor", [], "idinteriorcolor"),
        ...flatArray("idUpholstery", [object["idupholstery"]]),
        ...flatArray("idCondition", [object["idcondition"]]),
        ...getProp("fromMfk"),
        ...getProp("damagedVehicle"),
        ...getProp("nonSmokingVehicle"),
        ...getProp("directImport"),
        ...getProp("handicappedAccessible"),
        ...getProp("adOnlineSince"),
        ...getProp("sort"),
        ...getProp("fromCount"),
        // getProp("limitCount"),
        limitCount: 5,
        ...getProp("numberOfGearsFrom"),
        ...getProp("numberOfGearsTo"),
        ...getProp("powerFrom"),
        ...getProp("powerTo"),
        ...getProp("idDriveType"),
        ...getProp("totalWeightFrom"),
        ...getProp("totalWeightTo"),
        ...getProp("payloadFrom"),
        ...getProp("payloadTo"),
        ...getProp("trailerLoadFrom"),
        ...getProp("trailerLoadTo"),
        ...flatArray("idTrailerHitch", [object["idtrailerhitch"]]),
        ...getProp("fuelConsumptionCombinedTo"),
        ...getProp("fuelConsumptionUrbanTo"),
        ...getProp("fuelConsumptionExtraUrbanTo"),
        ...getProp("co2EmissionTo"),
        ...flatArray("idEuroNorm"),
        ...flatArray("idAd"),
        ...getProp("idTransmission"),
        ...getProp("guarantee"),
        ...getProp("particulateFilter"),
        ...getProp("catalyticConverter"),
        ...getProp("doorCountFrom"),
        ...getProp("doorCountTo"),
        ...getProp("numberOfSeatsFrom"),
        ...getProp("numberOfSeatsTo"),
        ...getProp("alloyWheels"),
        ...getProp("variant"),
        ...getProp("kerbWeightFrom"),
        ...getProp("kerbWeightTo"),
        ...getProp("engineSizeFrom"),
        ...getProp("engineSizeTo"),
        ...getProp("cylinder"),
        ...getProp("engineType"),
        ...getProp("energyEfficiencyClass"),
        ...getProp("radius"),
        ...getProp("longitude"),
        ...getProp("latitude"),
        ...flatArray("equipment"),
      };
      break;
    case "search":
      transformedData = {
        ...getProp("makeId"),
        ...getProp("modelId"),
        ...getProp("year"),
        ...getProp("price"),
      };
      break;
    default:
      return;
  }
  return transformedData;
};

export const sanitizeValue = (value) => {
  return (Array.isArray(value) && ["All", -1].includes(value[0])) ||
    value === -1 ||
    value === 0
    ? null
    : value;
};

export const setSearchProps = (dispatch, key, value) => {
  const sanitizedValue = sanitizeValue(value);
  if (key === "idMake") {
    dispatch(setFindProps("idModel", null));
    if (sanitizedValue === null) {
      dispatch(setFindProps(key, sanitizedValue));
      dispatch(resetCarModel());
      return;
    }
    dispatch(getCarModel(sanitizedValue[0]));
  }

  dispatch(setFindProps(key, sanitizedValue));
};

export const getPropName = (array, elementId, key) => {
  if (elementId === null || elementId === undefined) return "N/A";

  let lang = localStorage.getItem("i18nextLng");
  if (!lang) lang = "EN";
  lang = lang.toUpperCase();

  // this is not reliable, it will be adjusted later
  const prop = array.find((item) => item[key] === elementId);
  if (!!prop && typeof prop.name === "object") {
    return prop?.name?.[lang] ?? "";
  }
  return prop?.name;
};

export const getPropShortName = (array, elementId, key) => {
  if (!elementId) return "";
  const prop = array.find((item) => item[key] === elementId);
  if (!!prop && typeof prop.short_name === "object") return prop.short_name.EN;
  return null;
};

export const sorter = (data, type) => {
  if (!data) return;
  switch (type) {
    case "ascending": {
      return {
        data: data.sort((a, b) => a.price - b.price),
        label: "Low To Hight",
      };
    }
    case "descending": {
      return {
        data: data.sort((a, b) => b.price - a.price),
        label: "Hight To Low",
      };
    }
    default: {
      return {
        data: data.sort((a, b) => a.price - b.price),
        label: "Low To Hight",
      };
    }
  }
};

export const sortByAlphabet = (data, type, key) => {
  if (data === undefined || data.length == 0 || !Array.isArray(data)) return [];
  switch (type) {
    case "asc": {
      const sortedData = data
        .slice()
        .sort((a, b) =>
          a[key]?.toLowerCase() > b[key]?.toLowerCase() ? 1 : -1
        );
      return sortedData;
    }
    case "desc": {
      const sortedData = data
        .slice()
        .sort((a, b) =>
          a[key]?.toLowerCase() > b[key]?.toLowerCase() ? -1 : 1
        );
      return sortedData;
    }
    default: {
      const sortedData = data
        .slice()
        .sort((a, b) =>
          a[key]?.toLowerCase() > b[key]?.toLowerCase() ? 1 : -1
        );
      return sortedData;
    }
  }
};

export const sortByNumeric = (data, type, key) => {
  if (data === undefined) return;
  switch (type) {
    case "asc": {
      const sortedNumbers = data.slice().sort((a, b) => a[key] - b[key]);
      return sortedNumbers;
    }
    case "desc": {
      const sortedNumbers = data.slice().sort((a, b) => b[key] - a[key]);
      return sortedNumbers;
    }
    default: {
      const sortedNumbers = data.slice().sort((a, b) => a[key] - b[key]);
      return sortedNumbers;
    }
  }
};

export const uploadPhotoRequirements = (photo) => {
  const photoToUpload = photo ?? "";
  const dataArr = photoToUpload.split("base64,");
  const fileSize = str2ab(atob(dataArr[1])).byteLength;
  const atobImage = atob(dataArr[1]);
  return { photoToUpload, fileSize, atobImage };
};

export const uploadPhoto = (photo) => {
  let array_buffer = str2ab(photo);
  if (array_buffer) {
    startUpload(array_buffer);
  }
};

export const getCurrentLocale = (locales) => {
  const currentLanguage = (
    i18n.language || window.localStorage.i18nextLng
  ).toLowerCase();
  const currentLocale = locales.filter(
    (item) => item.locale.toLowerCase() === currentLanguage
  );
  return currentLocale[0].idlocale;
};

export const getUserProfileValue = (key, userData, props) => {
  const { chooseType } = props;
  if (chooseType === USER_TYPES.STANDARD) {
    return userData[key] || "";
  }
  if (chooseType === USER_TYPES.MERCHANT) {
    if (key === "first_name" || key === "last_name") {
      return userData.name[key] || "";
    }
    if (
      key === "postno" ||
      key === "street" ||
      key === "zip" ||
      key === "place"
    ) {
      return userData.address[key] || "";
    }
    return userData[key] || "";
  }
};

export const getObjectLength = (object) => {
  return Object.keys(object).length;
};

export const getTotalPages = (data = []) => {
  const adsPerPage = 5;
  let totalPages = parseInt(data.length / adsPerPage, 10);

  if (data.length % adsPerPage > 0) {
    totalPages++;
  }
  return totalPages;
};

/**
 *-----------------------------------------------
 * REMOVE PROPERTIES FROM OBJECT
 *-----------------------------------------------
 * @param {Object} object
 * @param {Array} props
 * @returns {Object}
 */
export const removePropsFromObject = (object, props) => {
  let data = {};
  Object.keys(object).forEach((key) => {
    if (!props.includes(key)) {
      data = { ...data, [key]: object[key] };
    }
  });
  return data;
};

/**
 *-----------------------------------------------
 * GET FAVORITES
 *-----------------------------------------------
 * @returns { array }
 */

export const getFavoritesIds = () => {
  return JSON.parse(localStorage.getItem("fav_ids"));
};

/**
 *-----------------------------------------------
 * SET FAVORITES
 *-----------------------------------------------
 * @param { idad } number
 */

export const setFavoriteIds = (favoriteIds) => {
  localStorage.setItem("fav_ids", JSON.stringify(favoriteIds));
};

/**
 *-----------------------------------------------
 * TOGGLE FAVORITE AD
 *-----------------------------------------------
 * @param { idad } number
 */

export const toggleFavorites = (idad) => {
  let favoriteIds = getFavoritesIds();

  if (favoriteIds && favoriteIds.length > 0) {
    if (favoriteIds.includes(idad)) {
      favoriteIds = favoriteIds.filter((item) => item !== idad);
    } else {
      favoriteIds.push(idad);
    }
  } else {
    favoriteIds = [idad];
  }

  setFavoriteIds(favoriteIds);
};

/**
 *-----------------------------------------------
 * SCROLL TO ELEMENT
 *-----------------------------------------------
 * @param { queryTarget } string
 */
export const scroll = (queryTarget) => {
  const section = document.querySelector(queryTarget);
  if (section) {
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  } else {
    console.warn(`Element not found: ${queryTarget}`);
  }
};

export const emailRegex = /\S+@\S+\.\S+/;

export const numberWithCommas = (number) => {
  return !isNaN(number) ? parseInt(number).toLocaleString("de-CH") : "";
};

export const thousandSeparatorWithPeriod = (number) => {
  return !isNaN(number) ? parseInt(number).toLocaleString("da-DK") : "";
};

export const toDateFormat = (value) => realMoment(value).format("DD/MM/YYYY");

export const acceptOnlyNumbers = (value) => {
  const re = /^[0-9+\b]+$/;
  if (value === "" || re.test(value)) {
    return true;
  }
  return false;
};

export const doNotShowLetterE = (event) => {
  const prohibitedKeys = ["e", "E", "+", "-"];
  if (prohibitedKeys.includes(event.key)) {
    event.preventDefault();
  }
};

export const formatDateString = (month, year) => {
  if (month === "" || year === "") {
    return "";
  }

  const monthString =
    month !== undefined ? (month < 10 ? `0${month}` : month.toString()) : "MM";
  const yearString = year !== undefined ? year.toString() : "YYYY";
  return `${monthString}-${yearString}`;
};

export const reverseFormatDateString = (dateString) => {
  const parts = dateString.split("-");
  const month = parseInt(parts[0]);
  const year = parseInt(parts[1]);
  if (isNaN(month)) {
    return {
      month: null,
      year: year,
    };
  }
  if (isNaN(year)) {
    return {
      month: month,
      year: null,
    };
  }
  return {
    month: month,
    year: year,
  };
};

export const mapConditionMaintenance = (res) => {
  let conditionmaintenance = {};
  res[0]?.conditionmaintenance &&
    Object.entries(res[0]?.conditionmaintenance).forEach((entry) => {
      const key = entry[0];
      const value = entry[1];
      switch (entry[0]) {
        case "lasttechnicalservice":
          if (value !== undefined && value !== null) {
            const reverseValue = reverseFormatDateString(value);
            conditionmaintenance = {
              ...conditionmaintenance,
              [key]: {
                month: reverseValue.month,
                year: reverseValue.year,
              },
            };
          }
          break;
        default:
          conditionmaintenance = { ...conditionmaintenance, [key]: value };
      }
    });
  return conditionmaintenance;
};

export const formatAddress = (streetAndNumber, city, country) => {
  let address = "";
  address = addTwoStringWithComma(address, streetAndNumber);
  address = addTwoStringWithComma(address, city);
  address = addTwoStringWithComma(address, country);
  return address;
};

export const addTwoStringWithComma = (acc, string) => {
  let result = acc;
  result += string ? `${result ? ", " : ""}${string}` : "";
  return result;
};

export const countFilters = (find_data, filterKeys) => {
  const countedFilters = {
    priceFrom: false,
    priceTo: false,
    yearFrom: false,
    yearTo: false,
    mileageFrom: false,
    mileageTo: false,
    powerFrom: false,
    powerTo: false,
  };

  return filterKeys.reduce((counter, filterKey) => {
    const filterValue = find_data[filterKey];

    if (countedFilters[filterKey]) {
      return counter;
    }

    if (
      (filterKey.startsWith("price") ||
        filterKey.startsWith("year") ||
        filterKey.startsWith("mileage") ||
        filterKey.startsWith("power")) &&
      filterValue !== null &&
      !isNaN(filterValue)
    ) {
      const pairKey = filterKey.replace("From", "").replace("To", "");
      countedFilters[`${pairKey}From`] = true;
      countedFilters[`${pairKey}To`] = true;
      return counter + 1;
    }

    if (Array.isArray(filterValue)) {
      return counter + filterValue.filter((item) => item !== null).length;
    }

    if (typeof filterValue === "number" && !isNaN(filterValue)) {
      countedFilters[filterKey] = true;
      return counter + 1;
    }

    return counter;
  }, 0);
};

// Function to convert PS to KW
export const psToKw = (psValue) => {
  const kwValue = psValue * 0.7355;
  return parseFloat(kwValue.toFixed(0)); // Return as number
};

// Function to convert KW to PS
export const kwToPs = (kwValue) => {
  const psValue = kwValue * 1.35962;
  return psValue.toFixed(0);
};

// Function to chunk array
export const chunkArray = (array, size) => {
  const chunkedArray = [];
  let index = 0;
  while (index < array.length) {
    chunkedArray.push(array.slice(index, index + size));
    index += size;
  }
  return chunkedArray;
};

export const max = (array = []) => {
  return array.reduce((acc, item) => {
    return item > acc ? item : acc;
  }, 0);
};

export const changeUploadStatus = (array, status, itemIndex = null) => {
  return array.map((item, index) => {
    let additionalProperties = {};
    if (index === itemIndex) {
      additionalProperties.status = status;
    }
    return {
      ...item,
      ...additionalProperties,
    };
  });
};

export const formatPhoneNumber = (number) => {
  if (!number) {
    return "";
  }

  // Remove all spaces from the phone number
  const cleanedNumber = number.replace(/\s+/g, "");
  // Check if the cleaned number starts with "0" and format accordingly
  if (cleanedNumber.startsWith("0")) {
    return `tel://+41${cleanedNumber.substring(1)}`;
  }
  return `tel://${cleanedNumber}`;
};

// Remove all dots from string
export const removeDotsAndSpaces = (str) => {
  return str.replace(/[.\s]/g, "");
};

// Function to format date from 'YYYYMM' to 'MM.YYYY'
export const formatTargaDate = (dateNum) => {
  const dateStr = dateNum.toString();
  const year = dateStr.slice(0, 4);
  const month = dateStr.slice(4, 6);
  return `${month}.${year}`;
};

export const getMainImage = (photos = []) => {
  if (!photos || photos.length === 0) {
    return null;
  }
  let mainImage = photos[0];
  if (!mainImage) {
    mainImage = photos.find((photo) => !!photo);
  }
  return mainImage;
};

// limit array index
export const limitedArrayRender = (arr, length) => {
  return arr.length > length ? arr.slice(0, length) : arr;
};
