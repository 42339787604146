import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setGeneralData,
  setVehicleData,
  setEngineEnviroment,
  setFuelConsuption,
  setRootDataProps,
} from "../store/actions";

const useVehicleData = () => {
  const dispatch = useDispatch();
  const selectedTargaData = useSelector((state) => state.car.selectedTargaData);
  const targaVehicleType = useSelector((state) => state.car.vehicleType);

  const vehicleData = selectedTargaData?.[1];
  const vehicleDataFullList = selectedTargaData?.[0];

  useEffect(() => {
    if (vehicleData) {
      const mappedGeneralData = {
        power: vehicleDataFullList.ps,
        variant: targaVehicleType,
      };

      const mappedVehicleData = {
        doorcount: vehicleData.doorcount,
        idnumberofseats: vehicleData.numberofseats,
      };

      const mappedEngineEnvironment = {
        iddrivetype: vehicleData.iddrivetype,
        cylinder: vehicleData.cylinder,
        co2emission: vehicleData.co2emission,
        idtransmission: vehicleData.idtransmission,
        kerbweight: vehicleData.gewleer,
        numberofgears: vehicleData.numberofgears,
      };

      const mappedFuelConsumption = {
        combined: vehicleData.combined,
        urban: vehicleData.urban,
        extraurban: vehicleData.extraurban,
      };
      const idmake = vehicleData.idmake;
      const idmodel = vehicleData.idmodel;
      const idbodytype = vehicleData.idbodytype;
      const idfueltype = vehicleData.idfueltype;
      dispatch(
        setRootDataProps({
          idmake,
          idmodel,
          idbodytype,
          idfueltype,
        })
      );
      dispatch(setGeneralData(mappedGeneralData));
      dispatch(setVehicleData(mappedVehicleData));
      dispatch(setEngineEnviroment(mappedEngineEnvironment));
      dispatch(setFuelConsuption(mappedFuelConsumption));
    }
  }, [vehicleData]);
};

export default useVehicleData;
