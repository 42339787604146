import { useEffect } from "react";

export default function ScrollToTop({ dependency }) {
  useEffect(() => {
    scrollToTop();
  }, [...dependency]);

  return null;
}

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};
