import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getTotalPages } from "../../lib/utils";
import {
  deleteAds,
  getAdsForOwner,
  publishAd,
  unpublishAd,
} from "../../store/actions";
import { ADS_PER_PAGE, MYCARS } from "../../data";
import { DialogModal } from "../../components/dialog";
import history from "../../lib/history";
import Radio from "../../components/radio";
import FormControl from "../../components/form-control";
import Ads from "../../components/ads";
import SimpleLoader from "../../components/simple-loader";
import "./index.css";

const MyCars = (props) => {
  const { dispatch, myCars, loading, uploadLoading, adUnpublished } = props;
  const { t, i18n } = useTranslation(["common", "mycars"]);
  const [ads, setAds] = useState([]);
  const [adsToPreview, setAdsToPreview] = useState([]);
  const [activePage, setActivePage] = useState(null);
  const [paginationStep, setPaginationStep] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [sortLabel, setSortLabel] = useState("common:recommended");
  const [readyToSort, setReadyToSort] = useState(false);
  const title =
    activePage === MYCARS.ACTIVE.ID
      ? t("common:active-vehicles")
      : t("common:list-of-drafted-ads");
  const [openModal, setOpenModal] = useState(false);
  const [idad, setIdad] = useState(null);
  const [dialogActionMode, setDialogActionMode] = useState(null);
  const [dialogData, setDialogData] = useState({});
  /**
   *-----------------------------------------------
   * SET DIALOG MODE
   *-----------------------------------------------
   *
   * @param {Number} mode
   */
  const openDialog = (mode, idad) => {
    if (mode === "delete") {
      setDialogData({
        title: "adDetails:delete-ad-title",
        message: "adDetails:delete-ad-message",
        submessage: "adDetails:delete-ad-submessage",
        buttons: [
          {
            label: "common:back",
            color: "white",
            className: "cancel",
            action: hideDialog,
          },
          {
            label: "common:delete",
            color: "red",
            className: "confirm",
            action: () => modalAction(mode, idad),
          },
        ],
      });
    }
    if (mode === "publish") {
      setDialogData({
        title: "common:publish",
        message: "common:publish-confirmation",
        buttons: [
          {
            label: "common:cancel",
            color: "white",
            className: "cancel",
            action: hideDialog,
          },
          {
            label: "common:confirm",
            color: "red",
            className: "confirm",
            action: () => modalAction(mode, idad),
          },
        ],
      });
    }
    if (mode === "unpublish") {
      setDialogData({
        title: "common:unpublish",
        message: "common:unpublish-confirmation",
        buttons: [
          {
            label: "common:cancel",
            color: "white",
            className: "cancel",
            action: hideDialog,
          },
          {
            label: "common:confirm",
            color: "red",
            className: "confirm",
            action: () => modalAction(mode, idad),
          },
        ],
      });
    }
    setDialogActionMode(mode);
    setIdad(idad);
    setOpenModal(true);
  };

  /**
   *-----------------------------------------------
   * SET DIALOG MODE
   *-----------------------------------------------
   *
   */
  const hideDialog = () => {
    setOpenModal(false);
    setDialogData({});
    setIdad(null);
  };

  /**
   *-----------------------------------------------
   * SET DIALOG MODE
   *-----------------------------------------------
   *
   */
  const modalAction = (mode, idad) => {
    if (mode === "delete") {
      dispatch(deleteAds(idad));
    }
    if (mode === "publish") {
      setDialogActionMode(null);
      dispatch(publishAd(idad));
    }
    if (mode === "unpublish") {
      setDialogActionMode(null);
      dispatch(unpublishAd(idad));
    }
    const pinAds = JSON.parse(localStorage.getItem("pinAds")) ?? [];
    if (pinAds.includes(idad)) {
      pinAds.splice(pinAds.indexOf(idad), 1);
      localStorage.setItem("pinAds", JSON.stringify(pinAds));
    }
    hideDialog();
  };

  /**
   *-----------------------------------------------
   * SWITCH PAGE
   *-----------------------------------------------
   *
   * @param {Event} event
   */
  const switchPage = (event) => {
    setAds([]);
    const newActivePage = parseInt(event.target.value, 10);
    setActivePage(newActivePage);
    setSortLabel("common:recommended");
    setPaginationStep(1);
  };
  /**
   *-----------------------------------------------
   * PAGINATION LOGIC
   *-----------------------------------------------
   *
   * @param {Number} currentPage
   */
  const paginationChangeHandler = (currentPage) => {
    setPaginationStep(currentPage);
  };

  /**
   *-----------------------------------------------
   * SORT AND RETURN SORTED DATA
   *-----------------------------------------------
   */
  const setSortReqiurements = (idsort) => {
    let data = myCars.filter((item) => {
      if (
        (activePage === MYCARS.ACTIVE.ID && item.state === 1) ||
        (activePage === MYCARS.DRAFTS.ID && item.state === 0)
      ) {
        return true;
      }
      return false;
    });

    let sortMethod = 1;
    let sortField = "publishtime";

    if (idsort === 1) {
      // Newest
      sortMethod = 1;
      sortField = "publishtime";
    } else if (idsort === 2) {
      // Latest
      sortMethod = -1;
      sortField = "publishtime";
    }

    if (activePage === MYCARS.DRAFTS.ID) {
      sortField = "createdat";
    }

    data.sort((a, b) => {
      const aVar = Date.parse(a[sortField]);
      const bVar = Date.parse(b[sortField]);

      if (aVar > bVar) {
        return -1 * sortMethod;
      } else if (aVar < bVar) {
        return 1 * sortMethod;
      }
      return 0;
    });

    return data;
  };

  /**
   *-----------------------------------------------
   * GET SORTED DATA AND CALL NARROW-DOWN
   *-----------------------------------------------
   */
  const handleSort = (idsort) => {
    switch (idsort) {
      case 0:
        setSortLabel("common:recommended");
        break;
      case 1:
        setSortLabel("common:newest");
        break;
      case 2:
        setSortLabel("common:latest");
        break;
      default:
        setAnchorEl(null);
        return;
    }
    // Apply the sorting logic
    const sortedData = setSortReqiurements(idsort);
    setAnchorEl(null);
    setPaginationStep(1);
    setAds(sortedData);
    // Save the sort state to localStorage
    localStorage.setItem("sortLabel", sortLabel);
    localStorage.setItem("sortIndex", idsort); // Save the sort index for later use
  };

  /**
   *-----------------------------------------------
   * NARROW DOWN ADS TO 5 PER PAGE
   *-----------------------------------------------
   */
  const narrowDownAds = (data = [], currentStep = null) => {
    const numberOfPages = getTotalPages(data);
    let paginationState = paginationStep;
    if (currentStep) {
      paginationState = currentStep;
    }
    const updatedData = data.filter(
      (item, index) =>
        index >= ADS_PER_PAGE * paginationState - ADS_PER_PAGE &&
        index <= ADS_PER_PAGE * paginationState - 1
    );
    setAdsToPreview(updatedData);
    setTotalPages(numberOfPages);
  };

  /**
   *-----------------------------------------------
   * PREVIEW AD
   *-----------------------------------------------
   *
   * @param {Number} idad
   */
  const previewAd = (idad) => {
    localStorage.setItem("previewAd", true);
    localStorage.setItem("activePage", activePage);
    localStorage.setItem("paginationStep", paginationStep);
    localStorage.setItem("sortLabel", sortLabel);
    history.push({
      pathname: `/${i18n.language}/ad/${idad}`,
      state: {
        activePage: activePage,
        paginationStep: paginationStep,
        sortLabel: sortLabel,
      },
    });
  };

  /**
   *-----------------------------------------------
   * SET PIN HANDLER
   *-----------------------------------------------
   */

  const pinHandler = (idAd) => {
    const pinAds = JSON.parse(localStorage.getItem("pinAds")) ?? [];
    if (pinAds.includes(idAd)) {
      pinAds.splice(pinAds.indexOf(idAd), 1);
      localStorage.setItem("pinAds", JSON.stringify(pinAds));
    } else {
      pinAds.push(idAd);
      localStorage.setItem("pinAds", JSON.stringify(pinAds));
    }
    dispatch(getAdsForOwner());
    setPaginationStep(1);
  };

  /**
   *-----------------------------------------------
   * SET ACTIVE STEP
   *-----------------------------------------------
   */
  useEffect(() => {
    const drafts = props?.location?.state?.drafts;
    if (dialogActionMode !== null) {
      const data = myCars.filter((item) => {
        if (activePage === MYCARS.ACTIVE.ID && item.state === 1) {
          return item;
        }
        if (activePage === MYCARS.DRAFTS.ID && item.state === 0) {
          return item;
        }
      });
      setAds(data);
    } else {
      if (drafts === true) {
        setActivePage(2);
        const data = myCars.filter((item) => item.state === 0);
        setAds(data);
      } else {
        setActivePage(1);
        const data = myCars.filter((item) => item.state === 1);
        setAds(data);
      }
    }
  }, [myCars, props.location.state?.drafts]);

  /**
   *-----------------------------------------------
   * SET INITIAL ADS
   *-----------------------------------------------
   */
  useEffect(() => {
    if (readyToSort) {
      narrowDownAds(ads, 1);
      setReadyToSort(false);
    } else {
      if (ads?.length > 0) {
        narrowDownAds(ads);
      } else {
        narrowDownAds([]);
      }
    }
  }, [ads]);

  /**
   *-----------------------------------------------
   * SET ADS ON PAGINATION CHANGE
   *-----------------------------------------------
   */
  useEffect(() => {
    if (!readyToSort) {
      narrowDownAds(ads);
    }
  }, [paginationStep]);

  /**
   *-----------------------------------------------
   * SET ADS ON PAGE CHANGE
   *-----------------------------------------------
   */
  useEffect(() => {
    if (activePage === MYCARS.ACTIVE.ID) {
      const data = myCars.filter((item) => item.state === 1);
      setAds(data);
    }
    if (activePage === MYCARS.DRAFTS.ID) {
      const data = myCars.filter((item) => item.state === 0);
      setAds(data);
    }
  }, [activePage]);

  /**
   *-----------------------------------------------
   * GET ADS AFTER UNPUBLISHING
   *-----------------------------------------------
   */
  useEffect(() => {
    if (adUnpublished) {
      dispatch(getAdsForOwner());
      setPaginationStep(1);
    }
  }, [adUnpublished]);

  useEffect(() => {
    const previewAd = JSON.parse(localStorage.getItem("previewAd"));
    if (previewAd) {
      const savedActivePage = parseInt(localStorage.getItem("activePage"), 10);
      const savedPaginationStep = parseInt(
        localStorage.getItem("paginationStep"),
        10
      );
      const savedSortLabel = localStorage.getItem("sortLabel");
      setActivePage(savedActivePage || 1);
      setPaginationStep(savedPaginationStep || 1);
      setSortLabel(savedSortLabel || "common:recommended");
      // Determine the sort index based on the saved sort label
      const sortIndex =
        savedSortLabel === "common:recommended"
          ? 0
          : savedSortLabel === "common:newest"
          ? 1
          : 2;
      // Apply sorting logic and update ads
      const sortedData = setSortReqiurements(sortIndex);
      setAds(sortedData);
      // Clear localStorage after restoring state
      localStorage.removeItem("previewAd");
      localStorage.removeItem("activePage");
      localStorage.removeItem("paginationStep");
      localStorage.removeItem("sortLabel");
    } else {
      // Default behavior on initial load
      dispatch(getAdsForOwner());
    }
  }, []);

  useEffect(() => {
    if (readyToSort) {
      narrowDownAds(ads, 1);
      setReadyToSort(false);
    } else if (sortLabel) {
      const sortIndex =
        sortLabel === "common:recommended"
          ? 0
          : sortLabel === "common:newest"
          ? 1
          : 2;
      const sortedData = setSortReqiurements(sortIndex);
      setAds(sortedData);
    }
  }, [sortLabel]);

  return (
    <SimpleLoader loading={(activePage !== 1 && activePage !== 2) || loading}>
      <div className="mycars">
        <aside>
          <header></header>
          <div>
            <FormControl>
              <Radio
                name="mycars"
                value={1}
                label={t("common:active-ads")}
                checked={activePage === MYCARS.ACTIVE.ID}
                changeHandler={switchPage}
              />
              <Radio
                name="mycars"
                value={2}
                label={t("common:drafts")}
                checked={activePage === MYCARS.DRAFTS.ID}
                changeHandler={switchPage}
              />
            </FormControl>
          </div>
        </aside>
        <main>
          <Ads
            data={adsToPreview}
            path={activePage === 1 ? "mycars" : "drafts"}
            totalPages={totalPages}
            activePage={paginationStep}
            paginationHandler={paginationChangeHandler}
            loading={activePage === 1 ? loading : loading || uploadLoading}
            title={title}
            sortLabel={sortLabel}
            openSort={(e) => setAnchorEl(e.currentTarget)}
            anchorEl={anchorEl}
            handleSort={handleSort}
            dropdownData={[
              "common:recommended",
              "common:newest",
              "common:latest",
            ]}
            previewAd={previewAd}
            openDialog={openDialog}
            pinHandler={pinHandler}
          />
        </main>
        <DialogModal
          hideDialog={hideDialog}
          openModal={openModal}
          data={dialogData}
        />
      </div>
    </SimpleLoader>
  );
};

const stateToProps = (state) => {
  const { myCars, loading, adCreated, adUnpublished } = state.car;
  const uploadLoading = state.common.loading;
  return {
    myCars,
    loading,
    adCreated,
    uploadLoading,
    adUnpublished,
  };
};

export default connect(stateToProps)(MyCars);
