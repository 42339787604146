import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getVehiclesForCode18,
  getVehiclesForCode24,
  resetAdProps,
  setRootAdData,
  resetErrors18And24,
  resetVehicleData18And24,
} from "../../../store/actions";
import { getMonthsData, years } from "../../../data";
import HelpInfo from "./components/help-modal";
import Button from "../../../components/button";
import Select from "../../../components/select";
import FormControl from "../../../components/form-control";
import { scrollToTop } from "../../../lib/scroll-to-top";
import { removeDotsAndSpaces } from "../../../lib/utils";

import "./index.css";

const IdentifyVehicle = (props) => {
  const {
    dispatch,
    redirect,
    ad_data,
    vehicleData18,
    vehicleData24,
    vehicleData18Error,
    vehicleData24Error,
  } = props;
  const { t } = useTranslation(["common", "sell"]);
  const [inputTargaCode18, setInputTargaCode18] = useState("");
  const [inputTargaCode24, setInputTargaCode24] = useState("");
  const [monthError, setMonthError] = useState("");
  const [yearError, setYearError] = useState("");
  const [isHelpWindowOpen, setHelpWindowOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const months = getMonthsData();
  const [targa24Error, setTarga24Error] = useState(null);
  const [targa18Error, setTarga18Error] = useState(null);

  const continueWithoutRegistration = () => {
    dispatch(resetAdProps());
    redirect(true);
    scrollToTop();
  };

  const openModal = (imageName) => {
    setModalContent(imageName);
    setHelpWindowOpen(true);
  };

  const closeModal = () => {
    setHelpWindowOpen(false);
  };

  const goToModelTypeSelectPage = () => {
    const { month, year } = ad_data;
    if (!month) {
      setMonthError(t("common:choose-both-dates"));
    } else {
      setMonthError("");
    }

    if (!year) {
      setYearError(t("common:choose-both-dates"));
    } else {
      setYearError("");
    }
    if (month && year) {
      if (inputTargaCode18.length > 0 && !isFetching) {
        setIsFetching(true);
        const code18WithoutDots = removeDotsAndSpaces(inputTargaCode18);
        dispatch(getVehiclesForCode18(code18WithoutDots, false));
      }
      if (inputTargaCode24.length > 0 && !isFetching) {
        setIsFetching(true);
        const code24WithoutSpaces = removeDotsAndSpaces(inputTargaCode24);
        dispatch(getVehiclesForCode24(code24WithoutSpaces, false));
      }
    }
  };

  useEffect(() => {
    dispatch(resetVehicleData18And24());
  }, [dispatch]);

  useEffect(() => {
    if (
      vehicleData18Error ||
      vehicleData24Error ||
      vehicleData18.length > 0 ||
      vehicleData24.length > 0
    ) {
      setIsFetching(false);
      setTarga24Error(vehicleData24Error ? t("sell:targa-24-error") : null);
      setTarga18Error(vehicleData18Error ? t("sell:targa-18-error") : null);
    }
  }, [vehicleData18Error, vehicleData24Error, vehicleData18, vehicleData24]);

  useEffect(() => {
    if (inputTargaCode18.length === 0 && inputTargaCode24.length === 0) {
      dispatch(resetErrors18And24());
    }
  }, [inputTargaCode18, inputTargaCode24]);

  return (
    <div className="identify-vehicle">
      <div className="form-wrapper">
        <div>
          <h4>{t("sell:with-registration-card")}</h4>
          <h5 className="italic">{t("sell:advantage-less-effort")}</h5>
        </div>
        <div className="form-container">
          <div className="document-section-forms">
            <div className="sec-18">
              <p>{t("sell:type-data-18")}</p>
              <p className="type-data-18-24">{t("sell:type-data-18-24")}</p>
              <div>
                <FormControl
                  inputType="textfield"
                  type="text"
                  placeholder={t("sell:section-18-placeholder")}
                  value={inputTargaCode18 || ""}
                  handleChange={(e) => setInputTargaCode18(e.target.value)}
                />
                <span
                  className="question-mark-18"
                  onClick={() => openModal("sec18")}
                >
                  ?
                </span>
              </div>
              <span className="error-message">{targa18Error}</span>
            </div>
            <span>{t("common:or")}</span>
            <div className="sec-24">
              <p>{t("sell:type-data-24")}</p>
              <div>
                <FormControl
                  inputType="textfield"
                  type="text"
                  placeholder={t("sell:section-24-placeholder")}
                  value={inputTargaCode24 || ""}
                  handleChange={(e) => setInputTargaCode24(e.target.value)}
                />
                <span
                  className="question-mark-24"
                  onClick={() => openModal("sec24")}
                >
                  ?
                </span>
              </div>
              <span className="error-message">{targa24Error}</span>
            </div>
          </div>
          {isHelpWindowOpen && (
            <HelpInfo content={modalContent} onClose={closeModal} />
          )}
          <div className="dates-section">
            <FormControl label={t("sell:date-of-construction")}>
              <span>
                <Select
                  label="month"
                  data={months}
                  handleChange={(event, newValue) =>
                    dispatch(
                      setRootAdData({
                        ...ad_data,
                        month: newValue ? newValue.month : "",
                      })
                    )
                  }
                  defaultValue={months.find(
                    (item) => item.month === ad_data?.month
                  )}
                />
                <span className="error-message">{monthError}</span>
              </span>
              <span>
                <Select
                  label="year"
                  data={years}
                  handleChange={(event, newValue) =>
                    dispatch(
                      setRootAdData({
                        ...ad_data,
                        year: newValue ? newValue.year : "",
                      })
                    )
                  }
                  defaultValue={years.find(
                    (item) => item.year === ad_data?.year
                  )}
                />
                <span className="error-message">{yearError}</span>
              </span>
              <Button
                disabled={
                  isFetching ||
                  (!inputTargaCode18.trim() && !inputTargaCode24.trim()) ||
                  !ad_data?.month ||
                  !ad_data?.year
                }
                click={goToModelTypeSelectPage}
                className="determine-btn"
                label={t("sell:determine-vehicle")}
                color="red"
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div>
        <div>
          <h4>{t("sell:without-registration-card")}</h4>
          <h5 className="italic">{t("sell:without-the-information")}</h5>
        </div>
        <Button
          click={continueWithoutRegistration}
          className="without-regdata-btn"
          color="transparent"
          label={t("sell:continue-without")}
        />
      </div>
    </div>
  );
};

const stateToProps = (state) => {
  const {
    ad_data,
    vehicleData18,
    vehicleData24,
    vehicleData18Error,
    vehicleData24Error,
  } = state.car;
  const { profileData } = state.profile;
  return {
    ad_data,
    vehicleData18,
    vehicleData24,
    vehicleData18Error,
    vehicleData24Error,
    profileData,
  };
};

export default connect(stateToProps)(IdentifyVehicle);
