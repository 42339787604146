import React, { useState, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { group, statistics } from "../../svg-icons/index";
import Pin from "../../../assets/shapes/pin.svg";
import Delete from "../../../assets/shapes/delete.svg";
import Edit from "../../../assets/shapes/edit.svg";
import Unpublish from "../../../assets/shapes/unpublish-new.svg";
import { PhotoPlaceholder } from "../../svg-icons/index";
import Button from "../../../components/button/index";
import Image from "../../../components/image/index";
import "./index.css";
import { connect } from "react-redux";
import {
  numberWithCommas,
  toDateFormat,
  getMainImage,
} from "../../../lib/utils.js";
import { publishAd } from "../../../store/actions";

const MyCar = (props) => {
  const { t, i18n } = useTranslation("common");
  const {
    previewAd,
    idad,
    openDialog,
    pinHandler,
    pin,
    generaldata,
    makename,
    modelname,
  } = props;
  const [showMenu, setShowMenu] = useState(false);
  const onlineSince = new Date(props.createdat);
  const publishSince = new Date(props.publishtime);
  const history = useHistory();

  const showMoreOptions = () => {
    setShowMenu(true);
    setTimeout(() => {
      setShowMenu(false);
    }, 3000);
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const window_size = useWindowSize();
  const mainImage = getMainImage(props.photos);

  return (
    <div className={`mycar ${window_size[0] < 1024 ? "small" : "big"}`}>
      <div className="mycar-image-container" onClick={() => previewAd(idad)}>
        {props.photos ? <Image src={mainImage} /> : <PhotoPlaceholder />}
      </div>
      <div className="mycar-header">
        <div className="makeandmodel">
          <h4>
            {makename} {modelname}
          </h4>
          <h4>
            {generaldata?.equipmentline ?? ""} {generaldata?.variant ?? ""}
          </h4>
        </div>
        {showMenu ? (
          <div className="more-options full-height">
            <div onClick={() => pinHandler(idad)}>
              <img src={Pin} alt="" />
              <span>{pin ? t("common:unpin") : t("common:pin")}</span>
            </div>
            <div onClick={() => openDialog("unpublish", idad)}>
              <img src={Unpublish} alt="" />
              <span>{t("common:unpublish")}</span>
            </div>
            <div
              onClick={() => {
                localStorage.setItem("editAd", "true");
                history.push(`/${i18n.language}/ad/${idad}/edit`);
              }}
            >
              <img src={Edit} alt="" />
              <span>{t("common:edit-ad")}</span>
            </div>
            <div onClick={() => openDialog("delete", idad)}>
              <img src={Delete} alt="" />
              <span>{t("common:delete")}</span>
            </div>
          </div>
        ) : (
          <span onClick={showMoreOptions} className="gorup">
            {group}
          </span>
        )}
      </div>
      <div className="mycar-main">
        <span>
          {statistics}
          <p>{t("common:statistics")}</p>
        </span>
        <div>
          <div>
            <p>
              {t("common:no-of-views")}: <span></span>
            </p>
            <p>
              {t("common:no-of-msgs")}: <span></span>
            </p>
            <p>
              {t("common:no-of-favs")}: <span></span>
            </p>
          </div>
          <div>
            <p className="adid">Ad ID No: CI-53758/21</p>
            {publishAd ? (
              <p>
                {t("common:online-since")}: {toDateFormat(publishSince)}
              </p>
            ) : (
              <p>
                {t("common:online-since")}: {toDateFormat(onlineSince)}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="mycar-footer">
        <Button
          color="red"
          label={`CHF ${numberWithCommas(props.price)}.-`}
          className="my-car-button"
          click={() => previewAd(idad)}
        />
        <div>
          <span>
            <p>{t("common:lowest-price-for-similar")}:</p>
            <p>{`CHF ${numberWithCommas()}-`}</p>
          </span>
          <span>
            <p>{t("common:highest-price-for-similar")}:</p>
            <p>{`CHF ${numberWithCommas()}-`}</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default connect()(MyCar);
