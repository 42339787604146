import React, { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import {
  numberWithCommas,
  getPropName,
  psToKw,
  getMainImage,
} from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import Button from "../../button";
import Image from "../../image";
import { PhotoPlaceholder, Share, Star } from "../../svg-icons/index";
import {
  Transsmision,
  Fuel,
  Power,
  KmIcon,
  RegistrationIcon,
} from "../../svg-icons";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import VehicleAddress from "./components/vehicle-address";
import { handleShareDeepLinking } from "../../../lib/deep-link";

const AdCard = (props) => {
  const {
    fuel_type,
    idad,
    photos,
    makename,
    modelname,
    address_data,
    name,
    company,
    vehiclelocation,
    month,
    year,
    idfueltype,
    price,
    generaldata,
    headline,
    engineenvironment,
    mileage,
    previewAd,
    toggleFavorites,
    favoriteIds,
    transmission,
  } = props;
  const { t, i18n } = useTranslation(["common"]);
  const NA = "N/A";
  const powerInPS = generaldata?.power;
  const powerInKW = psToKw(powerInPS);

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  const window_size = useWindowSize();
  const mainImage = getMainImage(photos);
  return (
    <>
      {window_size[0] < 1024 ? (
        <div className="base-card-tablet">
          <div className="ad-actions">
            <span
              onClick={() => handleShareDeepLinking(idad)}
              className="action-icon-wrapper"
            >
              <Share color="#969696" />
            </span>
            <span
              className="action-icon-wrapper"
              onClick={() => toggleFavorites(idad)}
            >
              <Star
                color={favoriteIds?.includes(idad) ? "#cc2c24" : "#969696"}
              />
            </span>
          </div>
          <a
            href={`${i18n.language}/ad/${idad}`}
            onClick={(e) => {
              e.preventDefault();
              previewAd(idad);
            }}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <div className="makeandmodel" onClick={() => previewAd(idad)}>
              <h5>{makename + " " + modelname}</h5>
              <h5>
                {generaldata?.equipmentline ?? ""} {generaldata?.variant ?? ""}
                {headline ?? ""}
              </h5>
            </div>
            <section className="ad-photo-and-features">
              <div onClick={() => previewAd(idad)} className="ad-photo-wrapper">
                {photos ? (
                  <Image src={mainImage} className="cover" />
                ) : (
                  <PhotoPlaceholder />
                )}
              </div>
              <div className="ad-features-wrapper">
                <div className="ad-features">
                  <div className="ad-feature-line">
                    <RegistrationIcon />
                    <span>
                      {month < 10 ? `0${month}` : month}/{year}
                    </span>
                  </div>
                  <div className="ad-feature-line img-lg">
                    <KmIcon />
                    <span>
                      {mileage ? `${numberWithCommas(mileage)} km` : NA}
                    </span>
                  </div>
                  <div className="ad-feature-line">
                    <Power />
                    <span>
                      {generaldata?.power ? `${powerInPS} PS` : NA}
                      <span className="power-kw-label"> ({powerInKW} KW)</span>
                    </span>
                  </div>
                  <div className="ad-feature-line">
                    <Fuel />
                    <span>
                      {fuel_type.length > 0 &&
                        getPropName(fuel_type, idfueltype, "idfueltype")}
                    </span>
                  </div>
                  <div className="ad-feature-line">
                    <Transsmision />
                    <span>
                      {transmission?.length > 0 &&
                        getPropName(
                          transmission,
                          engineenvironment?.idtransmission,
                          "idtransmission"
                        )}
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </a>

          <section className="ad-price-and-address">
            <div className="ad-price">CHF {numberWithCommas(price)}.-</div>
            <div className="ad-address">
              {address_data && vehiclelocation?.location ? (
                <>
                  <p className="no-margin">{company !== "" ? company : name}</p>
                  <p className="no-margin">
                    <VehicleAddress
                      vehiclelocation={vehiclelocation}
                      page="results"
                    />
                  </p>
                </>
              ) : (
                <p>
                  {t("profile:name")} & {t("profile:address-location")}
                </p>
              )}
            </div>
          </section>
        </div>
      ) : (
        <div className="base-card">
          <a
            href={`${i18n.language}/ad/${idad}`}
            onClick={(e) => {
              e.preventDefault();
              previewAd(idad);
            }}
            target="_blank"
            style={{ textDecoration: "none" }}
            rel="noreferrer"
          >
            <section>
              <div>
                {photos ? (
                  <Image src={mainImage} className="cover" />
                ) : (
                  <PhotoPlaceholder />
                )}
              </div>
            </section>
          </a>
          <section>
            <div>
              <a
                href={`${i18n.language}/ad/${idad}`}
                onClick={(e) => {
                  e.preventDefault();
                  previewAd(idad);
                }}
                target="_blank"
                style={{ textDecoration: "none" }}
                rel="noreferrer"
              >
                <div className="makeandmodel">
                  <h5>{makename + " " + modelname}</h5>
                  <h5>
                    {generaldata?.equipmentline
                      ? `${generaldata.equipmentline}`
                      : ""}{" "}
                    {generaldata?.variant ? `${generaldata.variant}` : ""}
                    {headline ?? ""}
                  </h5>
                </div>
              </a>
              <div>
                <span onClick={() => handleShareDeepLinking(idad)}>
                  <Share color="#969696" />
                </span>
                <Star
                  color={favoriteIds?.includes(idad) ? "#cc2c24" : "#969696"}
                  click={() => toggleFavorites(idad)}
                />
              </div>
            </div>
            <div>
              <ul>
                <li>
                  <RegistrationIcon />
                  {month < 10 ? `0${month}` : month} / {year}
                </li>
                <li>
                  <KmIcon />
                  {mileage ? `${numberWithCommas(mileage)} km` : NA}
                </li>
                <li>
                  <Power />
                  {generaldata?.power ? `${powerInPS} PS` : NA}
                  <span className="power-kw-label">({powerInKW} KW)</span>
                </li>
              </ul>
              <ul>
                <li>
                  <Fuel />
                  {fuel_type.length > 0 &&
                    getPropName(fuel_type, idfueltype, "idfueltype")}
                </li>
                <li>
                  <Transsmision />
                  {transmission?.length > 0 &&
                    getPropName(
                      transmission,
                      engineenvironment?.idtransmission,
                      "idtransmission"
                    )}
                </li>
              </ul>
            </div>
            <div className="card-controls">
              <Button
                label={`CHF ${numberWithCommas(price)}.-`}
                color="white"
                click={() => previewAd(idad)}
              />
              {window_size[0] < 1280 ? (
                <div>
                  {address_data && vehiclelocation?.location ? (
                    <>
                      {company && company.trim() !== "" ? company : name}
                      <p className="no-margin">
                        <VehicleAddress vehiclelocation={vehiclelocation} />
                      </p>
                    </>
                  ) : (
                    <p>
                      {t("profile:name")} & {t("profile:address-location")}
                    </p>
                  )}
                </div>
              ) : (
                <div>
                  <div>
                    {address_data && vehiclelocation?.location ? (
                      <>
                        <p className="no-margin">
                          {company !== "" ? company : name}
                        </p>
                        <p className="no-margin">
                          <VehicleAddress vehiclelocation={vehiclelocation} />
                        </p>
                      </>
                    ) : (
                      <p>
                        {t("profile:name")} & {t("profile:address-location")}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};

const stateToProps = (state) => {
  const { body_type, fuel_type, idlocale, transmission } = state.car;
  return {
    body_type,
    fuel_type,
    idlocale,
    transmission,
  };
};

export default connect(stateToProps)(AdCard);
