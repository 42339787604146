import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../../assets/images/carousel-home/clickable-image.png";
import {
  registerNewsletter,
  resetRegistrationSuccess,
} from "../../../store/profile/actions";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { backIcon } from "../../../components/svg-icons/index";
import Button from "../../../components/button";
import FormControl from "../../../components/form-control";
import Radio from "../../../components/radio";
import NewsletterImage from "../../../assets/images/newsletter/newsletter.png";
import ThanksPage from "../../../components/thanks-page/index";

import "./index.css";

const NewsLetter = (props) => {
  const { profileData, registrationSuccess, loading, registrationError } =
    props;
  const { t } = useTranslation(["footer", "common"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(profileData?.email || "");
  const [idLocale, setIdLocale] = useState(null);
  const [accepted, setAccepted] = useState(false);

  const labelHTML = `${t(
    "common:yes-i-accept"
  )} <a href="legal/terms-and-conditions" style="color: red;">T&C</a> ${t(
    "common:and-the"
  )} <a href="legal/privacy-protection" style="color: red;">${t(
    "footer:privacy-protection-declaration"
  )}</a>`;

  const goBack = () => {
    history.goBack();
  };

  const handleRadioChange = (e) => {
    setIdLocale(parseInt(e.target.value, 10));
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleCheckboxChange = () => {
    setAccepted((prev) => !prev);
  };

  const handleSubmit = () => {
    if (!email || !idLocale || !accepted) {
      alert(t("common:fill-required-fields"));
      return;
    }
    dispatch(registerNewsletter(email, idLocale));
  };

  useEffect(() => {
    dispatch(resetRegistrationSuccess());
    return () => {
      dispatch(resetRegistrationSuccess());
    };
  }, [dispatch]);

  useEffect(() => {
    if (registrationError) {
      const { errorCode, rawMessage } = registrationError;
      const translatedMessage =
        errorCode === 11
          ? t("common:email-already-registered")
          : rawMessage || t("common:global-error");
      toast.error(translatedMessage, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  }, [registrationError, t]);

  if (registrationSuccess) {
    return (
      <div className="newsletter-success">
        <ThanksPage texts={["auth:newsletter-success"]}></ThanksPage>
      </div>
    );
  }

  return (
    <div className="newsletter">
      <header>
        <div className="newsletter-go-back" onClick={goBack}>
          {backIcon}
          <p className="no-margin flex">{t("common:back")}</p>
        </div>
        <h3>{t("footer:newsletter")}</h3>
      </header>
      <main>
        <div className="image-wrapper">
          {Image && <img src={Image} alt="Car" />}
          <h3 className="instructions-text-title--mobile">
            {t("footer:newsletter-1")}
          </h3>
        </div>
        <div className="newsletter-instructions">
          <div className="instructions-text">
            <h3>{t("footer:newsletter-1")}</h3>
            <p>{t("footer:newsletter-2")}</p>
            <p>{t("footer:newsletter-3")}</p>
            <h3>{t("footer:newsletter-4")}</h3>
          </div>
          <h3 className="newsletter-modal-title--mobile">
            {t("footer:newsletter-4")}
          </h3>
          <div className="newsletter-modal">
            <div className="header">
              <h2>{t("footer:newsletter")}</h2>
              <span>
                <img src={NewsletterImage} alt="Newsletter" />
              </span>
            </div>
            <div className="main">
              <FormControl
                inputType="textfield"
                label={t("profile:email-address")}
                name="email"
                type="text"
                placeholder={t("common:type")}
                handleChange={handleInputChange}
                value={email}
              />
              <div className="radios">
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:german")}
                  value={1}
                  checked={idLocale === 1}
                  changeHandler={handleRadioChange}
                />
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:french")}
                  value={4}
                  checked={idLocale === 4}
                  changeHandler={handleRadioChange}
                />
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:italian")}
                  value={7}
                  checked={idLocale === 7}
                  changeHandler={handleRadioChange}
                />
                <Radio
                  name={"acceptance"}
                  className={"radio-button-target"}
                  label={t("common:english")}
                  value={2}
                  checked={idLocale === 2}
                  changeHandler={handleRadioChange}
                />
              </div>
              <div className="checkbox-and-button">
                <FormControl
                  inputType="checkbox"
                  label={
                    <span dangerouslySetInnerHTML={{ __html: labelHTML }} />
                  }
                  handleChange={handleCheckboxChange}
                  value={accepted}
                />
                <Button
                  type="submit"
                  color="transparent"
                  label={t("common:register")}
                  disabled={!email || !idLocale || !accepted || loading}
                  click={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { profileData, registrationSuccess, loading, registrationError } =
    state.profile;
  return {
    profileData,
    registrationSuccess,
    loading,
    registrationError,
  };
};

export default connect(mapStateToProps)(NewsLetter);
